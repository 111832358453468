.hljs {
  display: block;
  overflow-x: auto;
  background: #f0f2f4;
  padding-left: 50px;
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-selector-id {
  color: #9c3328;
}
.hljs-number {
  color: #75438a;
}
.hljs {
  color: #75438a;
}
.hljs-attribute {
  color: #1d75b3;
}
.hljs-code,
.hljs-class .hljs-title,
.hljs-section {
  color: white;
}
.hljs-regexp,
.hljs-link {
  color: #d39745;
}
.hljs-meta {
  color: #1d75b3;
}
.hljs-tag,
.hljs-name,
.hljs-bullet,
.hljs-subst,
.hljs-emphasis,
.hljs-type,
.hljs-built_in,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-addition,
.hljs-variable,
.hljs-template-tag,
.hljs-template-variable {
  color: #047d65;
}
.hljs-string,
.hljs-symbol {
  color: #b35e14;
}
.hljs-comment,
.hljs-quote,
.hljs-deletion {
  color: #75787b;
}
.hljs-selector-class {
  color: #047d65;
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-doctag,
.hljs-title,
.hljs-section,
.hljs-type,
.hljs-name,
.hljs-strong {
  font-weight: bold;
}

code,
kbd,
samp {
  font-size: 12px;
}
