.ui.grid + .grid {
  margin-top: unset;
}

.ui.grid {
  margin-top: unset;
}

.ui.grid > .column:not(.row) {
  padding-bottom: unset;
}

.ui.container {
  width: unset;
}

.ui.column.grid > [class*='twelve wide'].column,
.ui.grid > .column.row > [class*='twelve wide'].column,
.ui.grid > .row > [class*='twelve wide'].column,
.ui.grid > [class*='twelve wide'].column {
  padding-left: 2rem;
}
